import React from "react";

import JSONData from "../../content/documentIndex.json";

import sanitizeHtml from 'sanitize-html';
import classNames from 'classnames';
import { Link } from "gatsby";
import './WordCloud.css'

const convertToSlug = (text) => {
  return '/' + sanitizeHtml(text
    .toLowerCase()
    .replace(/\./g, '/')
    .replace(/ +/g, '-')
    .replace(/&/g, 'amp')
    .replace(/;/g, '')
    .replace(/\)/g, '')
    .replace(/\(/g, '')
    .replace(/'/g, '-and-x27-'))  
}

const getPadding = (descendants) => {

  let defaultStyle = 'p-5 text-secondary font-bold'
  let style;
  if (descendants >= 10) {
    style = ' node-xl'
  } else if (descendants >= 6) {
    style = ' node-lg'
  } else if (descendants >= 2) {
    style = ' node-md'
  } else if (descendants >= 1) {
    style = ' node-sm'
  } else {
    style = ' node-link'
  }
  return defaultStyle + style

}

export default function WordCloud() {
  return (
    <div className="flex flex-wrap  justify-center ">
      {JSONData.map((data, index) => {
        return (
          <>

            <Link to={convertToSlug(data.documentName)}>
              <button
                key={`content_item_${index}`}
                className={classNames(getPadding(data.descendants))}>
                {data.title} 
              </button>
            </Link>
          </>
        );
      })}

    </div>
  );
}
