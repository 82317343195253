import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import { SEngOpt } from "../components/seo";
import Layout from "../components/Layout";
import Product from "../images/svg/cubeV3color.svg";
import WordCloud from "../components/WordCloud";

const MoreInfo = ({ data }) => {
  const [moreData, setMoreData] = useState(null);

  useEffect(() => {
    const morePage = data.MoreInfoPageQuery.rawMarkdownBody.split(/---*/);
    setMoreData(morePage);
  }, [data]);

  return (
    <>
      <Layout title="more info">
        <div className="flex flex-col-reverse align-center justify-center m-auto md:max-w-4xl p-1">
          <div className="w-full ">
            {moreData && (
              <div className=" flex flex-row mx-auto justify-between items-center">
                <div></div>
                <article className="prose lg:prose-xl">
                  <ReactMarkdown className="react-markDown">
                    {moreData[0]}
                  </ReactMarkdown>
                </article>
                <div></div>
              </div>
            )}
            <Link to="/">
              <div className="flex align-center justify-center m-auto min-w-full p-5">
                <button className="flex space-x-2 items-center px-3 py-2 bg-white hover:bg-slate-50 rounded-md drop-shadow-md">
                  <div className="flex flex-col align-center justify-center m-auto">
                    <p className="text-s md:text-2xl text-secondary">Back</p>
                  </div>
                </button>
              </div>
            </Link>
            <WordCloud />
            <Link to="/">
              <div className="flex align-center justify-center m-auto min-w-full p-5">
                <button className="flex space-x-2 items-center px-3 py-2 bg-white hover:bg-slate-50 rounded-md drop-shadow-md">
                  <div className="flex flex-col align-center justify-center m-auto">
                    <p className="text-s md:text-2xl text-secondary">Back</p>
                  </div>
                </button>
              </div>
            </Link>
          </div>
          <div className="w-full self-center md:m-5 pb-8 ">
            <Product className="m-auto w-24 drop-shadow-md" />
            {/* <Product className="md:w-80 w-24 drop-shadow-md" /> */}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MoreInfo;

export const Head = () => <SEngOpt title="NST Wellbeing Coach more info" />;

export const query = graphql`
  {
    IndexPageQuery: markdownRemark(
      fileAbsolutePath: { regex: "/partials.Landing page.md/" }
    ) {
      rawMarkdownBody
      html
      frontmatter {
        id
        title
        desc
        updated
        created
      }
    }
    MoreInfoPageQuery: markdownRemark(
      fileAbsolutePath: { regex: "/partials.Landing page.More info.md/" }
    ) {
      rawMarkdownBody
      html
      frontmatter {
        id
        title
        desc
        updated
        created
      }
    }
  }
`;
